// formValidationReducer.ts

import _ from 'lodash'
import {ValidationAction, ValidationActionKind, ValidationState} from './form.t'
import * as Yup from 'yup'

// --- Type Definitions ---

/**
 * Represents a mapping of field paths to Yup schemas.
 */
type FlatSchemaFields = {
  [fieldPath: string]: Yup.AnySchema
}

/**
 * Represents a nested object structure for Yup schemas.
 */
interface NestedObject {
  [key: string]: Yup.AnySchema | NestedObject | Yup.AnySchema[]
}

/**
 * Transforms a flat Yup schema with dot-notated keys into a nested Yup schema.
 *
 * @param flatFields - A flat mapping of field paths to Yup schemas.
 * @returns The nested Yup object schema.
 */
function transformYupSchemaToNested(
  flatFields: FlatSchemaFields
): Yup.ObjectSchema<Yup.AnyObject, Yup.AnyObject> {
  const nested: NestedObject = {}

  Object.keys(flatFields).forEach((path) => {
    const parts = path.split('.')
    let current: any = nested

    parts.forEach((part, index) => {
      const arrayMatch = part.match(/(\w+)\[\]/)
      if (arrayMatch) {
        const arrayKey = arrayMatch[1]
        if (!current[arrayKey]) {
          current[arrayKey] = [] as Yup.AnySchema[]
        }
        if (!Array.isArray(current[arrayKey])) {
          console.warn(`Expected array at key "${arrayKey}", but found:`, current[arrayKey])
          return
        }
        if (current[arrayKey].length === 0) {
          ;(current[arrayKey] as Yup.AnySchema[]).push(Yup.object().shape({}) as Yup.AnySchema)
        }
        current = current[arrayKey][0] as NestedObject
      } else {
        if (index === parts.length - 1) {
          current[part] = flatFields[path]
        } else {
          if (!current[part]) {
            current[part] = {} as NestedObject
          }
          current = current[part] as NestedObject
        }
      }
    })
  })

  /**
   * Recursively builds the Yup schema shape.
   *
   * @param obj - The current level of the nested object.
   * @returns A Yup shape object.
   */
  function buildShape(obj: NestedObject): Record<string, Yup.AnySchema> {
    const shape: Record<string, Yup.AnySchema> = {}
    for (const key in obj) {
      const field = obj[key]
      if (Yup.isSchema(field)) {
        if (field instanceof Yup.ObjectSchema) {
          const nestedFields = field.fields as Record<string, Yup.AnySchema>
          shape[key] = Yup.object().shape(buildShape(nestedFields)) as Yup.ObjectSchema<
            Yup.AnyObject,
            Yup.AnyObject
          >
        } else if (field instanceof Yup.ArraySchema) {
          const arraySchema = field as Yup.ArraySchema<any, Yup.AnyObject>
          const innerSchema = arraySchema.innerType

          if (innerSchema instanceof Yup.ObjectSchema) {
            const nestedFields = innerSchema.fields as Record<string, Yup.AnySchema>
            shape[key] = Yup.array()
              .of(
                Yup.object().shape(buildShape(nestedFields)) as Yup.ObjectSchema<
                  Yup.AnyObject,
                  Yup.AnyObject
                >
              )
              .defined() as Yup.AnySchema
          } else {
            shape[key] = arraySchema as Yup.AnySchema
          }
        } else {
          // Primitive schema
          shape[key] = field as Yup.AnySchema
        }
      } else if (typeof field === 'object' && field !== null) {
        // Nested object without a specific schema
        shape[key] = Yup.object().shape(buildShape(field as NestedObject)) as Yup.ObjectSchema<
          Yup.AnyObject,
          Yup.AnyObject
        >
      } else {
        // Invalid field type
        console.warn(`Invalid field for key "${key}":`, field)
      }
    }
    return shape
  }

  const nestedShape = buildShape(nested)
  return Yup.object<Yup.AnyObject, Yup.AnyObject>().shape(nestedShape) as Yup.ObjectSchema<
    Yup.AnyObject,
    Yup.AnyObject
  >
}

/**
 * Extracts flat fields from a Yup schema.
 *
 * @param schema - The Yup schema to extract fields from.
 * @returns A flat mapping of field paths to Yup schemas.
 */
function extractFlatFieldsFromSchema(schema: Yup.AnySchema): FlatSchemaFields {
  const flatFields: FlatSchemaFields = {}

  if (schema instanceof Yup.ObjectSchema) {
    const shape = schema.fields
    for (const key in shape) {
      const subSchema = shape[key]
      if (subSchema instanceof Yup.ObjectSchema) {
        const nestedFields = extractFlatFieldsFromSchema(subSchema)
        for (const nestedKey in nestedFields) {
          flatFields[`${key}.${nestedKey}`] = nestedFields[nestedKey]
        }
      } else if (subSchema instanceof Yup.ArraySchema) {
        const arraySchema = subSchema as Yup.ArraySchema<any, Yup.AnyObject>
        const arrayItemSchema = arraySchema.innerType

        if (arrayItemSchema instanceof Yup.ObjectSchema) {
          const nestedFields = extractFlatFieldsFromSchema(arrayItemSchema)
          for (const nestedKey in nestedFields) {
            flatFields[`${key}[]${nestedKey ? `.${nestedKey}` : ''}`] = nestedFields[nestedKey]
          }
        } else if (Yup.isSchema(arrayItemSchema)) {
          flatFields[`${key}[]`] = arrayItemSchema as Yup.AnySchema
        } else {
          console.warn(`Invalid array item schema for key "${key}":`, arrayItemSchema)
        }
      } else if (Yup.isSchema(subSchema)) {
        flatFields[key] = subSchema as Yup.AnySchema
      } else {
        console.warn(`Invalid schema for key "${key}":`, subSchema)
      }
    }
  }

  return flatFields
}

// --- Reducer Function ---

/**
 * Reducer function to manage form validation schema.
 *
 * @param state - The current validation state.
 * @param action - The action to perform.
 * @returns The updated validation state.
 */
export const formValidationReducer = (
  state: ValidationState,
  action: ValidationAction
): ValidationState => {
  switch (action.kind) {
    case ValidationActionKind.ADD_FIELD: {
      const fieldSchema = action.field?.component?.validationSchema?.()
      if (!fieldSchema) return state

      // Assuming fieldSchema is Record<string, Yup.AnySchema>
      const newFlatFields: FlatSchemaFields = fieldSchema as FlatSchemaFields

      // Extract existing flat fields from the current schema
      const existingFlatFields = extractFlatFieldsFromSchema(state.schema)

      // Merge existing flat fields with the new flat fields
      const updatedFlatFields: FlatSchemaFields = {
        ...existingFlatFields,
        ...newFlatFields
      }

      // Transform the merged flat fields into a nested schema
      const nestedSchema = transformYupSchemaToNested(updatedFlatFields)

      return {
        ...state,
        schema: nestedSchema
      }
    }

    case ValidationActionKind.REMOVE_FIELD: {
      const fieldSchema = action.field?.component?.validationSchema?.()
      if (!fieldSchema) return state

      // Assuming fieldSchema is Record<string, Yup.AnySchema>
      const fieldsToRemove: FlatSchemaFields = fieldSchema as FlatSchemaFields
      const keysToRemove = Object.keys(fieldsToRemove)

      // Extract existing flat fields from the current schema
      const existingFlatFields = extractFlatFieldsFromSchema(state.schema)

      // Remove the specified fields
      const updatedFlatFields = _.omit(existingFlatFields, keysToRemove)

      // Transform the updated flat fields into a nested schema
      const nestedSchema = transformYupSchemaToNested(updatedFlatFields)

      return {
        ...state,
        schema: nestedSchema
      }
    }

    case ValidationActionKind.MERGE_SCHEMA: {
      const additionalSchema = action.schema
      if (!additionalSchema) return state

      // Extract flat fields from the additional schema
      const additionalFlatFields = extractFlatFieldsFromSchema(additionalSchema)

      // Extract existing flat fields from the current schema
      const existingFlatFields = extractFlatFieldsFromSchema(state.schema)

      // Merge existing flat fields with the additional flat fields
      const updatedFlatFields: FlatSchemaFields = {
        ...existingFlatFields,
        ...additionalFlatFields
      }

      // Transform the merged flat fields into a nested schema
      const nestedSchema = transformYupSchemaToNested(updatedFlatFields)

      return {
        ...state,
        schema: nestedSchema
      }
    }

    case ValidationActionKind.REMOVE_KEYS: {
      if (!action.keys) return state

      // Extract existing flat fields from the current schema
      const existingFlatFields = extractFlatFieldsFromSchema(state.schema)

      // Remove the specified keys
      const updatedFlatFields = _.omit(existingFlatFields, action.keys)

      // Transform the updated flat fields into a nested schema
      const nestedSchema = transformYupSchemaToNested(updatedFlatFields)

      return {
        ...state,
        schema: nestedSchema
      }
    }

    case ValidationActionKind.RESET: {
      // Reset to an empty nested schema
      return {
        ...state,
        schema: Yup.object<Yup.AnyObject, Yup.AnyObject>()
      }
    }

    default:
      return state
  }
}
